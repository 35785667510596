import {useEffect, useState} from "react";
import AxiosActions from "../axios/axios";
import {useAuth} from "../context/AuthContext";
import useLayoutContext from "./useLayoutContext";

const useLike = () => {

    const [likes, setLikes] = useState<Array<any>>([])
    const {token} = useAuth()
    const {setShowLoginPopup} = useLayoutContext()
    const loadLikes = async (token: string) => {
        try {
            const res = await AxiosActions.getFavoritesIds(token)
            setLikes(res.data)
        } catch (e) {

        }
    }

    useEffect(() => {
        if (token) {
            loadLikes(token).then()
        }
    }, [token])

    const dislike = async (id: any) => {
        if (token) {
            try {
                await AxiosActions.unsetFavoriteAdvert(id, token)
                let copyLikes = JSON.parse(JSON.stringify(likes));
                const index =  copyLikes.indexOf(id)
                if (index > -1) {
                    copyLikes.splice(index, 1)
                    setLikes(copyLikes)
                }
            } catch (e) {

            }
        }

    }

    const like = async (id: any) => {
        if (token) {
            try {
                await AxiosActions.setFavoriteAdvert(id, token)
                setLikes((prev: any) => ([...prev, id]))
            } catch (e) {

            }
        } else {
            setShowLoginPopup(true)
        }
    }


    return {likes, like, dislike}

};

export default useLike;