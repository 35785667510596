import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import styles from './Stars.module.scss';

const Stars: FC<TStars> = ({
    score,
    size = 'small',
    ...restProps
}): JSX.Element => {
    return (
        <div {...restProps} className={`${styles.container} ${styles[size]}`}>
            <div
                className={`${styles.icon} ${
                    score > 0.5 ? styles.icon__filled : ''
                }`}
            ></div>
            <div
                className={`${styles.icon} ${
                    score > 1.5 ? styles.icon__filled : ''
                }`}
            ></div>
            <div
                className={`${styles.icon} ${
                    score > 2.5 ? styles.icon__filled : ''
                }`}
            ></div>
            <div
                className={`${styles.icon} ${
                    score > 3.5 ? styles.icon__filled : ''
                }`}
            ></div>
            <div
                className={`${styles.icon} ${
                    score > 4.5 ? styles.icon__filled : ''
                }`}
            ></div>
        </div>
    );
};

export default Stars;

type TStars = {
    score: number;
    size?: 'big' | 'medium' | 'small';
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
