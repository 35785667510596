import Image from 'next/image';
import s from './SubCategoriesGrid.module.scss';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { ICategoryRoot } from '../../../models/ICategory';

const SubCategoriesGrid = ({
    items,
    parentCategory,
}: TSubCategoriesGrid) => {
    const { lang } = useTranslation('common');

    const subCategories = items?.map((category) => {
        const imageSrc =
            category.image_src ||
            '/assets/img/category/' + parentCategory + '.png';
        return (
            <li key={category.id}>
                <Link href={`/list/${category.url}`} className={s.item}>
                    <>
                        <div className={s.image}>
                            {imageSrc ? (
                                <Image
                                    src={imageSrc}
                                    style={{ objectFit: 'contain' }}
                                    fill
                                    alt={category.lang[lang]}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                        <div className={s.text}>{category.lang[lang]}</div>
                    </>
                </Link>
            </li>
        );
    });

    return (
        <>
            <menu className={s.categories}>{subCategories}</menu>
        </>
    );
};

type TSubCategoriesGrid = {
    items: ICategoryRoot[];
    parentCategory: string;
};

export default SubCategoriesGrid;
