import moment from 'moment';
import {LangMode} from "../models/ILang";
import {useEffect} from "react";

const calendars = {
    ru: {
        lastDay : '[Вчера]',
        sameDay : '[Сегодня]',
        nextDay : '[Завтра]',
        lastWeek : 'DD MMMM',
        nextWeek : 'DD MMMM',
        sameElse : 'DD MMMM'
    },
    en: {
        lastDay : '[Yesterday]',
        sameDay : '[Today]',
        nextDay : '[Tomorrow]',
        lastWeek : 'DD MMMM',
        nextWeek : 'DD MMMM',
        sameElse : 'DD MMMM'
    },
    sv: {
        lastDay : '[Yesterday]',
        sameDay : '[Today]',
        nextDay : '[Tomorrow]',
        lastWeek : 'DD MMMM',
        nextWeek : 'DD MMMM',
        sameElse : 'DD MMMM'
    },
}

const useMoment = (lang: string) => {
    moment.locale(lang)

    const date = (date: string | number, format: string = "MMMM Do, ha") => {
        return moment(date).format(format)
    }

    const ago = (days: number, format: string = "YYYY-MM-DD") => {
        if (days === 0) {
            return ""
        }
        return moment().subtract(days, "days").format(format)
    }

    const to = (date: string, format: string = "YYYY-MM-DD") => {
        if (date === "") {
            return 0
        }
        const now = moment()
        const from = moment(date, format)
        return now.diff(from, "days")
    }

    const fromNow = (date: string) => {
        return moment(date).calendar(null);
    }

    const timestamp = (date?: string): number => {
        return moment(date).unix()
    }

    useEffect(() => {
        moment.updateLocale(lang, {
            calendar: calendars[lang]
        })
    }, [lang])

    return {date, ago, to, fromNow, timestamp}
}

export default useMoment