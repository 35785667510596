import {
    ListActionTypes,
    SetAdvertsAction, SetLastVisitedAction,
    SetListFetchingAction,
    SetPageAction,
    SetPremiumAction,
    SetPremiumFetchingAction,
    SetSimpleAdvertsAction,
    SetSortIndexAction,
    SetTotalAction
} from "./types";
import {IAdvertShort} from "../../models/IAdvert";

export const ListActionCreator = {
    setListFetching: (fetching: boolean): SetListFetchingAction => (
        {type: ListActionTypes.SET_LIST_FETCHING, payload: fetching}
    ),
    setPremiumFetching: (fetching: boolean): SetPremiumFetchingAction => (
        {type: ListActionTypes.SET_PREMIUM_FETCHING, payload: fetching}
    ),
    setAdverts: (adverts: Array<IAdvertShort>): SetAdvertsAction => (
        {type: ListActionTypes.SET_ADVERTS, payload: adverts}
    ),
    setPremium: (premium: Array<IAdvertShort>): SetPremiumAction => (
        {type: ListActionTypes.SET_PREMIUM, payload: premium}
    ),
    setSimpleAdverts: (adverts: Array<IAdvertShort>): SetSimpleAdvertsAction => (
        {type: ListActionTypes.SET_SIMPLE_ADVERTS, payload: adverts}
    ),
    setPage: (page: number): SetPageAction => (
        {type: ListActionTypes.SET_PAGE, payload: page}
    ),
    setTotal: (total: number): SetTotalAction => (
        {type: ListActionTypes.SET_TOTAL, payload: total}
    ),
    setLastVisited: (adverts: Array<IAdvertShort>): SetLastVisitedAction => (
        {type: ListActionTypes.SET_LAST_VISITED, payload: adverts}
    ),
    setSortIndex: (sortIndex: number): SetSortIndexAction => (
        {type: ListActionTypes.SET_SORT_INDEX, payload: sortIndex}
    )
}
