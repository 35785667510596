import React from 'react';
import s from "./HomeAnnons.module.scss"
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import {publicApi} from "../../../redux/api";
import {Adsense} from "@ctrl/react-adsense";
import {backendUrl} from "../../../utils/url";
import AdLink from "../../AdLink/AdLink";

const HomeAnnons = () => {

    const {t} = useTranslation('common')

    const {data} = publicApi.useGetAdPlaceQuery({
        type: 'main'
    })

    let googleAds = null;
    let ownAds = null;
    if (!!data) {
        let countGoogleAds = 0
        if (!!data?.small_banner) {
            countGoogleAds = data.small_banner.count - data.small_banner?.ads?.length
            countGoogleAds = countGoogleAds > 0 ? countGoogleAds : 0
        }
        googleAds = Array.from(Array(countGoogleAds).keys()).map((i) => (
            <div className={s.image} key={i}>
                <Adsense
                    client={process.env.NEXT_PUBLIC_SENSE_CLIENT_ID}
                    slot={process.env.NEXT_PUBLIC_SENSE_SLOT}
                    adTest="on"
                />
            </div>
        ))

        ownAds = data.small_banner?.ads.slice(0, data.small_banner.count).map((ad) => (
            <div className={s.image} key={ad.id}>
                <AdLink ad={ad}>
                    <Image src={backendUrl(`storage/${ad.mobile_image}`)}
                        //    width={500} height={258}
                           style={{objectFit:"cover"}}
                           fill
                           priority={true}
                           alt={"banner"}/>
                </AdLink>
            </div>
        ))
    }

    return (
        <div className={s.annons}>
            <span className={s.title}>{t("Ad")}</span>
            <div className={s.wrapper}>
                {ownAds}
                {googleAds}
            </div>
        </div>
    );
};

export default HomeAnnons;
