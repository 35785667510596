import s from "./CardVertical.module.scss"
import Image from "next/image";
import useMoment from "../../../hooks/useMoment";
import {Icon} from "../../Icon/Icon";
import Link from "next/link"
import React, {DetailedHTMLProps, FC, HTMLAttributes, useContext} from "react";
import {LayoutContext} from "../../../context/LayoutContext";
import {useAuth} from "../../../context/AuthContext";
import {IAdvertShort} from "../../../models/IAdvert";
import {useRouter} from "next/router";
import AdvertUserStars from "../../AdvertUserStars/AdvertUserStars";

const CardVertical: FC<TCardVertical> = (
    {
        isLike,
        like,
        dislike,
        advert,
        ...restProps
    }): JSX.Element => {

    const {locale: lang} = useRouter()
    const {date} = useMoment(lang)
    const {setShowLoginPopup} = useContext(LayoutContext)
    const {user} = useAuth()

    let cardExtraClass = "";

  if (advert.highlighted) {
    cardExtraClass += ` ${s.cardHighlighted}`;
  }
  if (advert.premium) {
    cardExtraClass += ` ${s.cardPremium}`;
  }

    return (
        <div {...restProps} className={`${s.card}${!!cardExtraClass ? cardExtraClass  : '' }`}>
            <div className={`${s.like}`} onClick={(e) => {
                if (user) {
                    e.stopPropagation()
                    isLike ? dislike(advert.id) : like(advert.id)
                } else {
                    setShowLoginPopup(true)
                }
            }}>
                <Icon name={"like"} size={16} stroke={'red'} color={isLike ? 'red' : "none"}/>
            </div>
            <Link
                className={s.wrapper}
                href={`/advert/${advert.slug}/${advert.id}`}
                passHref
                title={advert.title}>

                <div className={s.image}>
                    <Image src={advert.image_src || "/assets/img/preloader.jpg"}
                           alt={advert.title}
                           fill
                           style={{objectFit:"cover"}}/>
                </div>
                <div className={s.caption}>
                    <div className={s.category}>
                        {advert.category.lang[lang]}
                    </div>
                    <div className={s.date}>
                        {date(advert.date)}
                    </div>
                </div>
                <div className={s.info}>
                    {<AdvertUserStars score={advert?.user?.score || 0}/>}
                    <div className={s.title}>
                        {advert.title}
                    </div>
                    <div className={s.address}>
                        {advert.address.region}, {advert.address.city}
                    </div>
                    <div className={s.description}>{advert.description}</div>
                    <div className={s.price_block}>
                        <div className={s.price}>
                            {advert.price && parseFloat(advert.price).toLocaleString("sv-SE", {
                                style: "currency",
                                currency: "SEK",
                                minimumFractionDigits: 0
                            })}
                        </div>

                        {(advert.premium ||  advert.highlighted)  && <div className={s.label}>Premium</div>}
                    </div>

                </div>

            </Link>
        </div>
    );

}

type TCardVertical = {
    isLike: boolean
    like: (id: number | string) => void
    dislike: (id: number | string) => void
    advert: IAdvertShort
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default CardVertical
