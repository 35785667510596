import {AdvertsListParams} from "../../axios/params";
import {AppDispatch} from "../store";
import {ListActionCreator} from "./actions";
import AxiosActions from "../../axios/axios";
import MapActionCreator from "../map/actions";
import {countMapCenter} from "../../helpers/countMapCenter";

const ListThunks = {
    getPremium: (root: string, mode: Array<"on_main_page" | "in_category">) => async (dispatch: AppDispatch) => {
        dispatch(ListActionCreator.setPremiumFetching(true))
        const response = await AxiosActions.getPremium(root, mode)
        dispatch(ListActionCreator.setPremium(response.data.items))
        dispatch(ListActionCreator.setPremiumFetching(false))

    },
    getAdverts: (body: any, params: AdvertsListParams) => async (dispatch: AppDispatch) => {
        dispatch(ListActionCreator.setListFetching(true))
        dispatch(MapActionCreator.setFetchingCoordinates(true))
        const response = await AxiosActions.getAdverts(body, params)
        dispatch(MapActionCreator.setCoordinates(response.data.coordinates))
        const center = countMapCenter(response.data.coordinates)
        dispatch(MapActionCreator.setCenter(center.latLng))
        dispatch(MapActionCreator.setZoom(center.zoom))

        dispatch(ListActionCreator.setAdverts(response.data.items))
        dispatch(ListActionCreator.setTotal(response.data.total))

        dispatch(MapActionCreator.setFetchingCoordinates(false))
        dispatch(ListActionCreator.setListFetching(false))
    },
    getSimpleAdverts: () => async (dispatch: AppDispatch) => {
      dispatch(ListActionCreator.setListFetching(true))
      const response = await AxiosActions.getSimpleAdverts()
      dispatch(ListActionCreator.setSimpleAdverts(response.data.items))
      dispatch(ListActionCreator.setListFetching(false))
    },
    getLastVisited: () => async (dispatch: AppDispatch) => {
        try {
            const response = await AxiosActions.getLastVisited()
            dispatch(ListActionCreator.setLastVisited(response.data.items))
        } catch (e) {
            console.log(e)
        }
    }
}

export default ListThunks
