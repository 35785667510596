import { FC, DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './AdLink.module.scss';
import {publicApi} from "../../redux/api";


const AdLink: FC<TAdLink> = (
    {
        ad,
        children,
        ...restProps
    }): JSX.Element => {
    const [increment] = publicApi.useIncrementClickMutation()
    const clickHandler = () => {
        increment(ad)

        if (!!ad?.link){
            window.open(ad?.link, '_blank');
        }
    }

    return (
        <div {...restProps} className={styles.container}
            onClick={clickHandler}
        >
            {children}
        </div>
    );
};


export default AdLink;


type TAdLink = {
    ad: any
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
