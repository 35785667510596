import type {NextPage} from 'next'
import Home from "../containers/Home/Home";
import {GetServerSideProps} from "next";
import AxiosActions from "../axios/axios";
import {ICategoryRoot} from "../models/ICategory";

const HomePage: NextPage = ({ rootMenu }: THomeProps ) => {
  return (
      <Home rootMenu={rootMenu}/>
  )
}
export const getServerSideProps: GetServerSideProps = async () => {
  let rootMenu = [] as ICategoryRoot[]
  try{
    const response = await AxiosActions.getRootMenu()
    rootMenu = response.data
  } catch (e) {
    console.log(e)
  }

  return {
    props: {
      rootMenu: rootMenu,
    }
  }
}

type THomeProps = {
  rootMenu: ICategoryRoot[]
}


export default HomePage
