import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import styles from './AdvertUserStars.module.scss';
import Stars from '../Stars/Stars';

const AdvertUserStars: FC<TAdvertUserStars> = ({
    score,
    size = "small",
    ...restProps
}): JSX.Element => (
    <div {...restProps} className={`${styles.container} ${styles[size]}`}>
        <div className={styles.icon}></div>
        <Stars score={score || 0}  size={size} />
        <div className={styles.number}>
            {score > 0 && Number(score).toFixed(1)}
        </div>
    </div>
);

export default AdvertUserStars;

type TAdvertUserStars = {
    score: number;
    size?: 'big' | 'medium' | 'small';
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
