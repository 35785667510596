import ICoordinate from "../models/ICoordinate";

export const countMapCenter = (coordinates: Array<ICoordinate>) => {

    if (coordinates.length === 0) {
        return {
            zoom: 4,
            latLng: {
                lat: 62.73479074268076,
                lng: 14.638577903931925,
            },
        }
    }

    const getMinMax = (nums: Array<number>) => {
        return {
            min: Math.min.apply(null, nums),
            max: Math.max.apply(null, nums)
        }
    }

    const latMinMax = getMinMax(coordinates.map(coordinate => parseFloat(coordinate.lat)))
    const lngMinMax = getMinMax(coordinates.map(coordinate => parseFloat(coordinate.lng)))

    const countBounds = (lat: {min: number, max: number}, lng: {min: number, max: number}) => {
        const deltaLan = Math.round((lat.max - lat.min) * 100)
        const deltaLng = Math.round((lng.max - lng.min) * 100)

        const maxDelta = Math.max(deltaLng, deltaLan)

        let zoom: number
        if (maxDelta > 400 || maxDelta === 0) {
            zoom = 5
        } else if (maxDelta > 300) {
            zoom = 7
        } else if (maxDelta > 200) {
            zoom = 8
        } else if (maxDelta > 100) {
            zoom = 9
        } else {
            zoom = 10
        }
        return zoom
    }

    return {
        latLng: {
            lat: (latMinMax.max + latMinMax.min) / 2,
            lng: (lngMinMax.max + lngMinMax.min) / 2
        },
        zoom: countBounds(latMinMax, lngMinMax)
    }
}