import {
    MapActionTypes,
    SetCenterAction,
    SetCoordinatesAction,
    SetExpandAction,
    SetFetchingCoordinatesAction,
    SetFetchingPopupAdvertAction,
    SetFormattedAddressAction,
    SetMapAction,
    SetPopupAdvertAction,
    SetPopupShowAction,
    SetZoomAction
} from "./types";
import ICoordinate from "../../models/ICoordinate";
import {IAdvertShort} from "../../models/IAdvert";

const MapActionCreator = {
    setFetchingCoordinates: (fetching: boolean): SetFetchingCoordinatesAction => (
        {type: MapActionTypes.SET_FETCHING_COORDINATES, payload: fetching}
    ),
    setFetchingPopupAdvert: (fetching: boolean): SetFetchingPopupAdvertAction => (
        {type: MapActionTypes.SET_FETCHING_POPUP_ADVERT, payload: fetching}
    ),
    setCoordinates: (coordinates: Array<ICoordinate>): SetCoordinatesAction => (
        {type: MapActionTypes.SET_COORDINATES, payload: coordinates}
    ),
    setPopupAdvert: (popupAdvert: IAdvertShort): SetPopupAdvertAction => (
        {type: MapActionTypes.SET_POPUP_ADVERT, payload: popupAdvert}
    ),
    setPopupShow: (show: boolean): SetPopupShowAction => (
        {type: MapActionTypes.SET_POPUP_SHOW, payload: show}
    ),
    setExpand: (expand: boolean): SetExpandAction => (
        {type: MapActionTypes.SET_EXPAND, payload: expand}
    ),
    setZoom: (zoom: number): SetZoomAction => (
        {type: MapActionTypes.SET_ZOOM, payload: zoom}
    ),
    setCenter: (center: google.maps.LatLngLiteral): SetCenterAction => (
        {type: MapActionTypes.SET_CENTER, payload: center}
    ),
    setMap: (map: google.maps.Map): SetMapAction => (
        {type: MapActionTypes.SET_MAP, payload: map}
    ),
    setFormattedAddress: (address: string): SetFormattedAddressAction => (
        {type: MapActionTypes.SET_FORMATTED_ADDRESS, payload: address}
    )
}

export default MapActionCreator